import { render, staticRenderFns } from "./scoreConfigurationAdd.vue?vue&type=template&id=029b42f5"
import script from "./scoreConfigurationAdd.vue?vue&type=script&lang=js"
export * from "./scoreConfigurationAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports